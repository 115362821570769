








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// https://cesium.com/public/SandcastleSampleData/big-buck-bunny_trailer.mp4

import tinymce from 'tinymce'
import Editor from '@tinymce/tinymce-vue'
// import { uploadOssFile } from '@/api/public'
// import { completeImg } from '@/utils/index'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/media'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/codesample'
// import "tinymce/plugins/emoticons"
import 'tinymce/icons/default/icons'
import { uploadFile } from '@/api/common'

console.log(tinymce)
var placeholders = ''
// var vm: this
@Component({ name: 'TinymceEditer', components: { Editor } })
export default class extends Vue {
  //设置prop id
  @Prop({ default: 'vue-tinymce-' + +new Date() }) id!: string

  //默认高度
  @Prop({ default: 500 }) height!: number

  @Prop({ default: '' })
  private value!: string

  @Prop({ default: '' })
  private type!: string

  @Prop({ default: '' })
  private placeholder!: string

  private content: string = ''

  @Watch('value')
  private onChangeValue(newVal: string) {
    this.content = newVal
  }

  private showPlace = true
  @Watch('content')
  private onChangeContent(newVal: string) {
    if (this.content === '') {
      this.showPlace = true
    } else {
      this.showPlace = false
    }
    this.$emit('input', newVal)
  }

  editor: any = null
  //富文本框init配置
  get init() {
    return {
      selector: '#' + this.id, //富文本编辑器的id
      language: 'zh_CN', //语言
      language_url: process.env.BASE_URL + 'tinymce/zh_CN.js', //语言包
      skin_url: process.env.BASE_URL + 'tinymce/skins/ui/oxide', //编辑器需要一个skin才能正常工作，所以要设置一个skin_url指向之前复制出来的skin文件
      plugins: 'image media fullscreen paste lists', //插件
      // toolbar_mode: 'wrap',
      // toolbar:
      //   'undo redo | bold italic underline strikethrough  | fontselect | fontsizeselect | formatselect  | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | removeformat| link unlink image media insertdatetime table  hr pagebreak codesample | fullscreen', //工具条
      toolbar: `bold italic underline
        | fontsizeselect
        | alignleft aligncenter alignright alignjustify
        | bullist numlist
        | image media
        | removeformat undo redo
        | fontselect  | formatselect  | forecolor backcolor
        | fullscreen strikethrough
        | outdent indent blockquote
        | link unlink insertdatetime table hr pagebreak codesample`, //工具条
      menubar: false,
      font_formats:
        'Arial=arial,helvetica,sans-serif;宋体=SimSun;微软雅黑=Microsoft Yahei;Impact=impact,chicago;', //字体
      fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 64px 72px', //文字大小
      height: 345, //高度
      branding: false, //水印
      elementpath: false, //底部元素路径
      paste_data_images: true, //允许粘贴图片
      file_picker_types: 'media',
      images_upload_credentials: true,
      //初始化前执行
      setup: (editor: any) => {
        this.editor = editor
        editor.on('init', function () {
          //   if (editor.getContent() == ''){
          //     editor.setContent(`<p class='placeholder' id='placeholder' style="color:#bebebe;font-size:14px">请输入</p>`)
          //     //  tinymce.get('Text').setContent('<p id=>Your nice text here!</p>')
          //   }
        })
        editor.on('focus', function () {
          // var element = editor.Html()
          // console.log(element)
          // element?.remove()
        })
      },
      //实例化执行
      init_instance_callback: (editor: any) => {
        this.content && editor.setContent(this.content)
        //this.hasInit = true
        editor.on('NodeChange Change KeyUp SetContent', () => {
          // var element = editor.iframeHTML

          // console.log(element)
          //this.hasChange = true
          this.content = editor.getContent()
        })
      },
      //选择文件
      file_picker_callback: (
        callback: (arg0: any) => void,
        value: any,
        meta: { filetype: string },
      ) => {
        if (meta.filetype == 'media') {
          const input: any = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'video/*')
          input.onchange = async (res: any) => {
            const file = input.files[0]
            if (file.size > 50 * 1024 * 1024) {
              this.$message.warn('视频不能超过50M')
              return false
            }
            const base64File = await this.toBase64File(file)
            this.handleFile(
              {
                base64: () => base64File.split(',')[1],
                filename: () => file.name,
              },
              callback,
              () => callback(''),
            )
          }
          //触发点击
          input.click()
        }
      },
      // 上传图片回调
      images_upload_handler: (
        blobInfo: any,
        success: Function,
        failure: Function,
      ) => {
        this.handleFile(blobInfo, success, failure)
      },
    }
  }

  private mounted() {
    placeholders = this.placeholder
    this.content = this.value
  }

  //上传图片
  async handleFile(blobInfo: any, success: Function, failure: Function) {
    const file = blobInfo.base64()
    const fileName = blobInfo.filename()
    const suffix = fileName.substring(
      fileName.lastIndexOf('.') + 1,
      fileName.length,
    )
    const params = {
      // 0公有，1私有
      // type: 0,
      type: 1,
      fileName,
      suffix,
      bytes: file,
    }
    const [err, res] = await uploadFile(params)
    if (err) {
      failure(err)
    } else {
      success(res.showUrl)
    }
  }

  toBase64File(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
    })
  }

  focusEditor() {
    this.editor.focus()
  }
}
